export const NODE_TYPES = {
  SOURCE: "SOURCE",
  DESTINATION: "DESTINATION",
  AUDIONODE: "AUDIONODE"
};

export const MODAL_TYPES = {
  ADD_INPUT: "ADD_INPUT",
  ADD_OUTPUT: "ADD_OUTPUT",
  ADD_AUDIO_NODE: "ADD_AUDIO_NODE",
  EDIT_AUDIO_NODE: "EDIT_AUDIO_NODE",
  HELP: "HELP",
  PERMISSIONS: "PERMISSIONS",
};
